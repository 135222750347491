import React, { useRef } from 'react';
import { ArtworkCard } from '@presentation/components/ArtworkCard';
import AliceCarousel from 'react-alice-carousel';
import { type Theme, useTheme } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import {
	ItemContainer,
	NavigationButtonLeft,
	NavigationButtonRight,
	Wrapper,
} from '@presentation/components/CardsCarusel/styles';
import type { ContentItemWithAuthorModel } from '@domain/models';
import { useMediaQuery } from '@mui/material';

export type CardsCarouselProps = {
	items: ContentItemWithAuthorModel[];
};

export const CardsCarousel = (props: CardsCarouselProps) => {
	const theme = useTheme();

	const carousel = useRef<AliceCarousel>(null);

	const { items } = props;

	const responsive = {
		0: { items: 1, itemsFit: 'contain' },
		[theme.breakpoints.values.sm]: { items: 2, itemsFit: 'contain' },
		[theme.breakpoints.values.md]: { items: 4, itemsFit: 'contain' },
		[theme.breakpoints.values.lg]: { items: 5, itemsFit: 'contain' },
	};

	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

	const isInfinite = isTablet || items.length > 5;

	return (
		<Wrapper maxWidth='xxl'>
			{items.length > 5 && (
				<>
					<NavigationButtonLeft onClick={(e) => carousel?.current?.slidePrev(e)}>
						<WestIcon />
					</NavigationButtonLeft>
					<NavigationButtonRight onClick={(e) => carousel?.current?.slideNext(e)}>
						<EastIcon />
					</NavigationButtonRight>
				</>
			)}
			<Wrapper maxWidth='xl'>
				<AliceCarousel
					disableDotsControls
					disableButtonsControls
					paddingRight={items.length > 5 ? 150 : 0}
					ref={carousel}
					infinite={isInfinite}
					responsive={responsive}
					items={items.map((content) => (
						<ItemContainer key={content.id}>
							<ArtworkCard
								id={content.id}
								creator={content.author}
								imageName={content.name}
								imageSrc={content.imageSrc}
								onAuthorClick={(event) => {
									event.stopPropagation();

									window.location.href = `/${content.author?.profileLink}`;
								}}
							/>
						</ItemContainer>
					))}
				/>
			</Wrapper>
		</Wrapper>
	);
};
