import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { type HomepageSectionContent, HomepageService } from '@domain/usecases/homepage';
import { HOMEPAGE_SECTIONS_LIMIT } from '@domain/usecases/homepage/HomepageConfig';
import type { HomepageCategoryItem } from '@domain/usecases/config/ConfigRepository';

export const useGetHomePageSection = (homepageService: HomepageService, collections: HomepageCategoryItem[]) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [sections, setSections] = useState<HomepageSectionContent[]>([]);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const isInProgress = useRef<boolean>(false);

	// Handlers
	const fetchContent = useCallback(async () => {
		if (isInProgress.current) {
			return;
		}
		isInProgress.current = true;

		const offset = HOMEPAGE_SECTIONS_LIMIT * page;

		try {
			const res = await homepageService.getNextHomepageSectionsData(
				collections.slice(offset, offset + HOMEPAGE_SECTIONS_LIMIT)
			);

			setSections((prevState) => {
				return [...prevState, ...res];
			});
			setHasMore(res.length >= HOMEPAGE_SECTIONS_LIMIT);
			setPage((prevState) => prevState + 1);
		} catch (e) {
			enqueueSnackbar(t('screens.home.errors.fetchDataError'), { variant: 'error' });
		} finally {
			isInProgress.current = false;
		}
	}, [enqueueSnackbar, t, homepageService, page, collections]);

	useEffect(() => {
		fetchContent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		sections,
		hasMore,
		fetchContent,
		isLoading: isInProgress.current,
	};
};
